import { useGlobalAuth } from '@keyliving/component-lib';
import { Fragment, lazy, LazyExoticComponent, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../hooks';

const HomeSearchLayout = lazy(() => import('../components/layout/HomeSearchLayout'));

const lookup = new Map<
    string,
    LazyExoticComponent<(props: { children?: ReactNode }) => JSX.Element>
>([['home-search', HomeSearchLayout]]);

export default function PrivateRoute() {
    const { isLoggedIn } = useAuth();
    const { redirectToAuth } = useGlobalAuth();

    // TODO: Determine the layout based on if home search or resident
    const userLayout = lookup.get('home-search');
    const Layout = userLayout ?? Fragment;

    if (!isLoggedIn) {
        /**
         * Redirect them to the /login page, but save the current page they were
         * trying to access when they were redirected.
         */
        redirectToAuth({
            relativeAuthRoute: '/magic-link',
            searchParams: {
                redirectUrl: window.location.href,
            },
        });

        return null;
    }

    return (
        <Layout>
            <Outlet />
        </Layout>
    );
}
