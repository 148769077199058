import { IconProps } from './types';

export default function DesjardinsIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 48 48"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M6,10c0-2.209,1.791-4,4-4h28c2.209,0,4,1.791,4,4v28c0,2.209-1.791,4-4,4H10c-2.209,0-4-1.791-4-4 V10z"
                fill="#00874e"
            />
            <path
                d="M24,37.789l-11.5-7.475V17.686L24,10.211l11.5,7.475v12.629L24,37.789z M15.5,28.686l8.5,5.525 l8.5-5.525v-9.371L24,13.789l-8.5,5.525V28.686z"
                fill="#fff"
            />
        </svg>
    );
}
