import { HouseIcon, MenuItemProps } from '@keyliving/component-lib';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import PublicLayout from '../components/layout/PublicLayout';
import NoMatch from '../pages/NoMatch';
import { HOME_SEARCH_URLS, PORTAL_URLS } from './urls';

const HomeSearchFinancial = lazy(() => import('../pages/home-search/Financial'));
const HomeSearchUpdateFinancial = lazy(
    () => import('../pages/home-search/Financial/UpdateFunding/UpdateFunding')
);
const HomeSearchDashboard = lazy(() => import('../pages/home-search/Dashboard'));
const HomeSearchMyHomeSearch = lazy(() => import('../pages/home-search/MyHomeSearch'));

/**
 * These are public routes that the app needs. These are used
 * in App.tsx and apply to everyone.
 */
export const publicRoutes: RouteObject[] = [
    {
        element: <PublicLayout />,
        children: [
            {
                path: '*',
                element: <NoMatch />,
            },
        ],
    },
];

/**
 * These are routes for logged in users only. We use this array
 * to create the protected routes in App.tsx and are for users
 * in the "home search" or "application" part of their journey.
 */
export const homeSearchAuthRoutes: MenuItemProps[] = [
    {
        path: HOME_SEARCH_URLS.Financial.path,
        label: 'Financial',
        showInMenu: true,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <HomeSearchFinancial />
                    </Suspense>
                ),
            },
            {
                path: HOME_SEARCH_URLS.Financial.Update.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <HomeSearchUpdateFinancial />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: HOME_SEARCH_URLS.Home.path,
        label: 'Dashboard',
        icon: <HouseIcon height={24} width={24} />,
        showInMenu: true,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <HomeSearchDashboard />
            </Suspense>
        ),
    },
    {
        path: HOME_SEARCH_URLS.MyHomeSearch.path,
        label: 'My home search',
        showInMenu: true,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <HomeSearchMyHomeSearch />
            </Suspense>
        ),
    },
];

/**
 * These are routes for logged in users only. We use this array to
 * create the protected routes in App.tsx and are for users  who
 * have successfully found a home and are now Owner Residents
 */
export const portalAuthRoutes: MenuItemProps[] = [
    {
        path: PORTAL_URLS.Home.path,
        label: 'Home',
        icon: <HouseIcon height={24} width={24} />,
        showInMenu: false,
        element: <div>Resident portal dashboard</div>,
    },
];
