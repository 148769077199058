import { IconProps } from './types';

export default function NBIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.2"
            viewBox="0 0 1483 1351"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <g id="g8">
                <g id="g6">
                    <path
                        d="m1482.3 159.5v1036.2l-556.5 148.5c-25.4 5.1-44.5 8.9-62.3 2.6-13.9-6.4-25.4-21.6-35.5-35.6l-171.6-236.1c-10.1-14-21.6-29.3-35.5-35.6-17.8-7.6-36.9-2.5-62.3 2.5l-557.8 148.6v-1034.9l556.5-148.5c25.5-5.1 44.5-10.2 62.3-2.6 14 5.1 25.4 21.6 35.6 35.6l171.5 234.9c10.2 14 21.6 30.5 35.6 35.5 19 7.7 36.8 3.9 62.2-2.5z"
                        fill="#e41c23"
                        id="path4"
                    />
                </g>
            </g>
        </svg>
    );
}
