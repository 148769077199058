import { route } from 'react-router-typesafe-routes/dom';

/**
 * NOTE: Validation helpers catch parsing and validation errors
 * and replace values with undefined.
 *
 * e.g. zod(string().email()) - Requires the email passed in
 * the url to be a valid email. Otherwise the value
 * returned by useTypedSearchParams for email
 * will be undefined
 */

export const HOME_SEARCH_URLS = {
    Financial: route(
        'financial',
        {},
        {
            Update: route('update'),
        }
    ),
    Home: route(''),
    MyHomeSearch: route('home-search'),
};

export const PORTAL_URLS = {
    Home: route(''),
};
