import { IconProps } from './types';

export default function MinIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="-1 -1 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M1.688 18.188h20.625M4.438 5.813l7.076 7.076a.688.688 0 0 0 .972 0l7.076-7.076"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}
