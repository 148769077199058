import { IconProps } from './types';

export default function HSBCIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.2"
            viewBox="0 0 1516 759"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path d="m379.5 0h757.1v757.1h-757.1z" fill="#ffffff" id="Layer" />
            <path d="m1136.6 758v-758l378.6 379.5z" fill="#db0011" id="Layer" />
            <path d="m379.5 0h757.1l-378.5 379.5z" fill="#db0011" id="Layer" />
            <path d="m379.5 0v758l-379.5-378.5z" fill="#db0011" id="Layer" />
            <path d="m1136.6 758h-757.1l378.6-378.5z" fill="#db0011" id="Layer" />
        </svg>
    );
}
