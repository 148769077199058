import { IconProps } from './types';

export default function ATBIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.1"
            viewBox="0 0 200 200"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M 33.100 73.750 C 30.908 80.763, 27.493 91.900, 25.511 98.500 C 23.528 105.100, 20.127 116.176, 17.953 123.113 C 15.779 130.050, 14 136.255, 14 136.902 C 14 137.735, 16.546 137.994, 22.743 137.789 L 31.486 137.500 34.103 128 L 36.719 118.500 47.688 118.219 L 58.656 117.938 61.613 127.719 L 64.570 137.500 73.785 137.788 C 79.165 137.955, 83 137.678, 83 137.121 C 83 135.917, 60.212 63.152, 59.447 61.915 C 59.136 61.412, 53.978 61, 47.984 61 L 37.086 61 33.100 73.750 M 67 61.595 C 67 61.922, 67.944 65.297, 69.097 69.095 L 71.195 76 80.083 76 L 88.971 76 89.236 106.750 L 89.500 137.500 98 137.500 L 106.500 137.500 106.764 106.750 L 107.029 76 117 76 L 126.971 76 127.236 106.750 L 127.500 137.500 141.872 137.778 C 164.740 138.221, 173.695 135.577, 179.750 126.595 C 182.548 122.445, 182.998 120.920, 182.984 115.637 C 182.962 107.542, 180.135 102.415, 173.799 98.979 L 169.150 96.458 173.168 94.146 C 178.195 91.252, 181.479 84.607, 180.711 78.881 C 179.365 68.845, 171.600 63.387, 156.602 61.935 C 146.492 60.957, 67 60.654, 67 61.595 M 44.093 87.650 C 42.256 94.718, 40.531 101.513, 40.260 102.750 C 39.780 104.940, 39.984 105, 47.951 105 C 55.283 105, 56.079 104.818, 55.598 103.250 C 55.303 102.287, 53.461 95.875, 51.506 89 C 49.550 82.125, 47.834 76.118, 47.692 75.650 C 47.550 75.183, 45.930 80.583, 44.093 87.650 M 144 84.101 L 144 92.203 151.250 91.507 C 160.148 90.653, 163 89.052, 163 84.911 C 163 78.518, 159.144 76, 149.351 76 L 144 76 144 84.101 M 144 115.145 L 144 126.289 150.472 125.685 C 161.024 124.699, 165 121.694, 165 114.701 C 165 107.861, 159.161 104.018, 148.750 104.006 L 144 104 144 115.145"
                fill="#f6f9f8"
                fillRule="evenodd"
                stroke="none"
            />
            <path
                d="M -0 100.005 L -0 200.010 100.250 199.755 L 200.500 199.500 200.755 99.750 L 201.010 0 100.505 0 L 0 0 -0 100.005 M 0.481 100.500 C 0.481 155.500, 0.602 178.147, 0.750 150.826 C 0.898 123.506, 0.898 78.506, 0.750 50.826 C 0.602 23.147, 0.481 45.500, 0.481 100.500 M 33.100 73.750 C 30.908 80.763, 27.493 91.900, 25.511 98.500 C 23.528 105.100, 20.127 116.176, 17.953 123.113 C 15.779 130.050, 14 136.255, 14 136.902 C 14 137.735, 16.546 137.994, 22.743 137.789 L 31.486 137.500 34.103 128 L 36.719 118.500 47.688 118.219 L 58.656 117.938 61.613 127.719 L 64.570 137.500 73.785 137.788 C 79.165 137.955, 83 137.678, 83 137.121 C 83 135.917, 60.212 63.152, 59.447 61.915 C 59.136 61.412, 53.978 61, 47.984 61 L 37.086 61 33.100 73.750 M 67 61.595 C 67 61.922, 67.944 65.297, 69.097 69.095 L 71.195 76 80.083 76 L 88.971 76 89.236 106.750 L 89.500 137.500 98 137.500 L 106.500 137.500 106.764 106.750 L 107.029 76 117 76 L 126.971 76 127.236 106.750 L 127.500 137.500 141.872 137.778 C 164.740 138.221, 173.695 135.577, 179.750 126.595 C 182.548 122.445, 182.998 120.920, 182.984 115.637 C 182.962 107.542, 180.135 102.415, 173.799 98.979 L 169.150 96.458 173.168 94.146 C 178.195 91.252, 181.479 84.607, 180.711 78.881 C 179.365 68.845, 171.600 63.387, 156.602 61.935 C 146.492 60.957, 67 60.654, 67 61.595 M 44.093 87.650 C 42.256 94.718, 40.531 101.513, 40.260 102.750 C 39.780 104.940, 39.984 105, 47.951 105 C 55.283 105, 56.079 104.818, 55.598 103.250 C 55.303 102.287, 53.461 95.875, 51.506 89 C 49.550 82.125, 47.834 76.118, 47.692 75.650 C 47.550 75.183, 45.930 80.583, 44.093 87.650 M 144 84.101 L 144 92.203 151.250 91.507 C 160.148 90.653, 163 89.052, 163 84.911 C 163 78.518, 159.144 76, 149.351 76 L 144 76 144 84.101 M 144 115.145 L 144 126.289 150.472 125.685 C 161.024 124.699, 165 121.694, 165 114.701 C 165 107.861, 159.161 104.018, 148.750 104.006 L 144 104 144 115.145"
                fill="#1d63ab"
                fillRule="evenodd"
                stroke="none"
            />
        </svg>
    );
}
