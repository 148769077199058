import { IconProps } from './types';

export default function BathIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="m17.84,21.19H6.17c-1.94,0-3.42-2.53-3.99-5.22h19.66c-.57,2.68-2.06,5.22-3.99,5.22ZM1.54,13.96h20.92c.26,0,.48.21.48.48s-.21.48-.48.48H1.54c-.26,0-.48-.21-.48-.48s.21-.48.48-.48ZM18.14,4.7h-1.97c.14-.55.49-.89.99-.89s.85.34.99.89Zm5.86,9.73c0-.78-.59-1.42-1.35-1.52V3.04c0-1.67-1.36-3.04-3.04-3.04s-2.92,1.25-3.03,2.83c-.94.26-1.55,1.18-1.55,2.41,0,.29.24.53.53.53h3.16c.29,0,.53-.24.53-.53,0-1.25-.63-2.17-1.59-2.42.11-.98.94-1.75,1.95-1.75,1.09,0,1.97.89,1.97,1.97v9.86H1.54c-.85,0-1.54.69-1.54,1.54,0,.69.46,1.27,1.08,1.46.59,3.13,2.39,6.36,5.09,6.36h11.67c2.7,0,4.5-3.23,5.09-6.36.62-.2,1.07-.77,1.07-1.46Zm-5.95-5.8c.29,0,.53-.24.53-.53v-.62c0-.29-.24-.53-.53-.53s-.53.24-.53.53v.62c0,.29.24.53.53.53Zm-1.35-1.61v3.28c0,.29-.24.53-.53.53s-.53-.24-.53-.53v-3.28c0-.29.24-.53.53-.53s.53.24.53.53Zm.82,4.62c0,.29.24.53.53.53s.53-.24.53-.53v-1.83c0-.29-.24-.53-.53-.53s-.53.24-.53.53v1.83Zm-2.18,11.07h1.77c.29,0,.53.24.53.53s-.24.53-.53.53h-1.77c-.29,0-.53-.24-.53-.53s.24-.53.53-.53Zm-7.14,0h-1.77c-.29,0-.53.24-.53.53s.24.53.53.53h1.77c.29,0,.53-.24.53-.53s-.24-.53-.53-.53ZM1.98,12.19s-.02,0-.03,0c-.29-.02-.52-.26-.5-.56.12-2.34,2.05-4.18,4.4-4.18,1.34,0,2.55.6,3.36,1.56.4-.18.83-.28,1.28-.28,1.62,0,2.99,1.27,3.1,2.89.02.29-.2.55-.49.57-.01,0-.03,0-.04,0-.28,0-.51-.21-.53-.49-.08-1.06-.97-1.9-2.04-1.9-.23,0-.46.04-.68.12.26.52.41,1.1.45,1.71.01.29-.21.54-.5.56-.3,0-.54-.21-.56-.5-.09-1.78-1.56-3.17-3.34-3.17s-3.25,1.39-3.34,3.17c-.01.28-.25.5-.53.5Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
