import { IconProps } from './types';

export default function ScotiabankIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.2"
            viewBox="0 0 1449 1591"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <g id="#ff0d1fff">
                <path
                    d="m577.4 8.7c57.2-11 115.7-6.8 173.6-7.6 232.6 0 465.2 0.2 697.7 0-89.9 99-180 197.8-270.1 296.6q-230.1 0.1-460.2 0c-128.3 0.6-255.4 53-346.9 142.9-81.7 79.1-134.8 187.1-146.9 300.2-44.8-80.1-66.9-172.9-62.2-264.6 4.7-98.8 39.8-196 99.5-274.9 75.7-101.2 191.1-171.1 315.5-192.6z"
                    fill="#ff0d1f"
                    id="Layer"
                />
                <path
                    d="m681.4 397.8c113.2-12.5 230.9 25.1 315.1 101.9 48.6 43.5 86.2 99.1 108.6 160.4 40.8 111 28.7 240.3-33.3 341.2-62.8 105.4-176.1 178.5-298 192.2-115.1 14.6-235.5-23.1-321.2-101.3-83-74-132.6-183.9-132.1-295.1-1.2-115.4 51.8-229.3 139.7-303.9 61.8-53.3 140.1-86.9 221.2-95.4z"
                    fill="#ff0d1f"
                    id="Layer"
                />
                <path
                    d="m1225 851.3c60.4 107.3 79 237.3 49.5 357-25.3 106.8-87.4 204.3-173.4 272.5-76.8 61.3-171.8 99.4-269.7 108-26.7 2.8-53.5 1.8-80.3 2-250.2 0-500.4-0.1-750.6 0q134.9-148.2 270-296.3c132.1 0.4 264.2 0 396.2 0.1 38.8-0.2 77.7 1.2 116.3-3.5 121.9-13.4 237.8-73.6 319.1-165.4 68.2-75.8 111.3-173.2 122.9-274.4z"
                    fill="#ff0d1f"
                    id="Layer"
                />
            </g>
        </svg>
    );
}
