import { useGlobalAuth } from '@keyliving/component-lib';
import { AuthData } from '@keyliving/shared-types';
import { isTokenExpired } from '@keyliving/utils';
import { useCallback } from 'react';

import {
    clearAuthDetails,
    PasswordlessRegisterParams,
    selectAuthData,
    setAuthDetails,
    usePasswordlessRegisterMutation,
} from '../redux/modules/auth';
import { useAppDispatch, useAppSelector } from './redux';

export default function useAuth() {
    const { claims, token, user } = useAppSelector(selectAuthData);
    const dispatch = useAppDispatch();
    const { deleteToken: deleteAuthToken } = useGlobalAuth();
    const tokenExpired = isTokenExpired(claims?.exp ?? null);

    const [registerWithoutPassword, { isLoading: registerLoading }] =
        usePasswordlessRegisterMutation();
    const isLoading = registerLoading;

    const passwordlessRegistration = useCallback(
        async ({ email, fullName, org }: PasswordlessRegisterParams): Promise<AuthData> => {
            try {
                const data = await registerWithoutPassword({ email, fullName, org }).unwrap();
                dispatch(setAuthDetails(data));

                return data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        [dispatch, registerWithoutPassword]
    );

    const signOut = () => {
        dispatch(clearAuthDetails());
        deleteAuthToken();
        sessionStorage.clear();
        return Promise.resolve();
    };

    return {
        user,
        claims,
        token,
        isLoggedIn: !!user && !tokenExpired,
        signOut,
        isLoading,
        passwordlessRegistration,
    };
}
