import { IconProps } from './types';

export default function TangerineIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.1"
            viewBox="0 0 439 447"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M 116.055 145.267 C 114.710 145.948, 112.736 148, 111.669 149.827 C 109.998 152.688, 109.887 153.680, 110.871 156.965 C 112.192 161.374, 114.057 162.754, 123 165.940 C 159.048 178.781, 186.956 201.580, 198.533 227.646 C 208.521 250.132, 210.294 278.953, 203.621 310.349 C 202.353 316.317, 201.523 322.284, 201.776 323.610 C 202.487 327.328, 207.508 331, 211.881 331 C 216.775 331, 219.379 328.187, 229.935 311.500 C 234.458 304.350, 240.465 294.900, 243.285 290.500 C 246.105 286.100, 252.876 275.525, 258.332 267 C 263.789 258.475, 269.912 249.025, 271.941 246 C 273.970 242.975, 275.943 239.825, 276.327 239 C 276.710 238.175, 281.181 231.110, 286.262 223.300 C 291.343 215.491, 298.173 204.916, 301.441 199.800 C 304.708 194.685, 311.117 184.754, 315.682 177.731 C 325.263 162.991, 326.260 159.637, 323.149 152.605 C 321.513 148.906, 320.198 147.538, 316.793 145.992 C 312.633 144.104, 309.501 144.044, 215.500 144.037 C 140.395 144.031, 117.948 144.309, 116.055 145.267"
                fill="#fbfbf9"
                fillRule="evenodd"
                stroke="none"
            />
            <path
                d="M 196.500 0.613 C 142.958 8.182, 103.745 26.765, 68.144 61.440 C 35.864 92.881, 14.789 130.471, 5 174.069 C -1.709 203.946, -1.709 243.054, 5 272.931 C 23.260 354.254, 84.908 419.264, 163.418 439.988 C 185.296 445.763, 191.283 446.454, 219.500 446.454 C 247.717 446.454, 253.704 445.763, 275.582 439.988 C 335.123 424.271, 386.297 382.401, 414.901 326 C 426.883 302.372, 435.498 274.006, 437.618 251.202 C 437.989 247.206, 438.677 243.700, 439.147 243.409 C 439.616 243.119, 440 233.657, 440 222.382 C 440 211.107, 439.613 202.121, 439.141 202.413 C 438.669 202.705, 437.981 199.701, 437.612 195.738 C 437.244 191.775, 436.036 184.025, 434.928 178.516 C 418.241 95.558, 355.582 28.119, 275.582 7.013 C 255.350 1.676, 248.217 0.789, 223 0.478 C 210.075 0.319, 198.150 0.379, 196.500 0.613 M 116.055 145.267 C 114.710 145.948, 112.736 148, 111.669 149.827 C 109.998 152.688, 109.887 153.680, 110.871 156.965 C 112.192 161.374, 114.057 162.754, 123 165.940 C 159.048 178.781, 186.956 201.580, 198.533 227.646 C 208.521 250.132, 210.294 278.953, 203.621 310.349 C 202.353 316.317, 201.523 322.284, 201.776 323.610 C 202.487 327.328, 207.508 331, 211.881 331 C 216.775 331, 219.379 328.187, 229.935 311.500 C 234.458 304.350, 240.465 294.900, 243.285 290.500 C 246.105 286.100, 252.876 275.525, 258.332 267 C 263.789 258.475, 269.912 249.025, 271.941 246 C 273.970 242.975, 275.943 239.825, 276.327 239 C 276.710 238.175, 281.181 231.110, 286.262 223.300 C 291.343 215.491, 298.173 204.916, 301.441 199.800 C 304.708 194.685, 311.117 184.754, 315.682 177.731 C 325.263 162.991, 326.260 159.637, 323.149 152.605 C 321.513 148.906, 320.198 147.538, 316.793 145.992 C 312.633 144.104, 309.501 144.044, 215.500 144.037 C 140.395 144.031, 117.948 144.309, 116.055 145.267 M 0.423 223.500 C 0.424 235.050, 0.570 239.638, 0.747 233.696 C 0.924 227.753, 0.923 218.303, 0.745 212.696 C 0.567 207.088, 0.422 211.950, 0.423 223.500"
                fill="#f38424"
                fillRule="evenodd"
                stroke="none"
            />
        </svg>
    );
}
