import { IconProps } from './types';

export default function MapIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M22.313 9.25V5.263a1.374 1.374 0 0 0-.865-1.277l-5.5-2.2a1.375 1.375 0 0 0-1.021 0L9.073 4.128a1.375 1.375 0 0 1-1.021 0L2.63 1.959a.687.687 0 0 0-.943.642v13.175a1.375 1.375 0 0 0 .864 1.277l5.5 2.2c.328.131.693.131 1.021 0l2.641-1.057M8.563 4.226V19.35M15.438 1.688v6.875"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.188 11.38a4.125 4.125 0 0 1 4.125 4.125c0 1.76-2.464 5.111-3.584 6.543a.685.685 0 0 1-1.083 0c-1.12-1.43-3.584-4.782-3.584-6.543a4.125 4.125 0 0 1 4.126-4.125Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.188 15.85a.344.344 0 1 1 0-.688M18.188 15.85a.344.344 0 1 0 0-.688"
                stroke="currentColor"
            />
        </svg>
    );
}
