import { IconProps } from './types';

export default function BedIcon({ height = 18, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 18"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M2.38 7.88h19.24a.6.6 0 0 0 .27-.07c.64.17 1.11.75 1.11 1.45v1.25H1V9.26c0-.69.47-1.28 1.11-1.45.08.05.17.07.27.07ZM19.5 6.76h1.61V.98H2.89v5.77H4.5V5.3c0-.85.7-1.55 1.56-1.55h3.63c.86 0 1.56.69 1.56 1.55v1.45h1.5V5.3c0-.85.7-1.55 1.56-1.55h3.63c.86 0 1.56.69 1.56 1.55v1.45Zm-1.04 0V5.31c0-.29-.23-.52-.52-.52h-3.63c-.29 0-.52.23-.52.52v1.45h4.67Zm-12.92 0h4.67V5.31c0-.29-.23-.52-.52-.52H6.06c-.29 0-.52.23-.52.52v1.45ZM1 11.63h22v3.13H1v-3.13Zm23-.56V9.26c0-1.14-.77-2.14-1.87-2.42V.49c0-.28-.23-.5-.51-.49S2.38 0 2.38 0c-.28 0-.5.22-.51.49v6.34C.77 7.11 0 8.11 0 9.25v6s0 .08.02.12c-.01.04-.02.08-.02.12v2c0 .28.22.5.5.5h2c.28 0 .5-.22.5-.5v-1.75h18v1.75c0 .28.22.5.5.5h2c.28 0 .5-.22.5-.5v-2s0-.08-.02-.12c.01-.04.02-.08.02-.13v-4.19ZM2 17H1v-1h1v1Zm20 0h1v-1h-1v1Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
