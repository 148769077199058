import { IconProps } from './types';

export default function BMOIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.2"
            viewBox="0 0 1532 1532"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="m1058.8 58.3c92.9 38.4 177.2 94.8 248.3 165.9 71.1 71.1 127.4 155.5 165.9 248.4 38.4 92.9 58.2 192.4 58.2 293 0 309.6-186.4 588.8-472.4 707.3-285.9 118.4-615 52.9-833.9-166-218.8-219-284.3-548.2-165.9-834.3 118.5-286.1 397.5-472.6 707-472.6 100.5 0 200 19.8 292.8 58.3z"
                fill="#ed1c24"
                id="Layer"
            />
            <path
                d="m741.5 531.6c2.9 3.4 6.5 6.2 10.5 8 4.1 1.9 8.5 2.9 12.9 2.9 4.5 0 8.9-1 12.9-2.9 4.1-1.8 7.7-4.6 10.5-8l131.7-173.6q2.1-2.7 4.8-4.9 2.7-2.2 5.8-3.7 3.1-1.5 6.4-2.3 3.4-0.7 6.8-0.8 3.4 0.1 6.6 0.9 3.2 0.8 6.2 2.3 2.9 1.5 5.5 3.5 2.6 2.1 4.7 4.7l159.2 199.3v312.4l-157.4-201.8q-2.1-2.7-4.8-4.9-2.7-2.2-5.8-3.7-3.1-1.5-6.4-2.3-3.4-0.7-6.8-0.8-3.6 0-7 0.8-3.5 0.8-6.7 2.3-3.2 1.6-5.9 3.8-2.8 2.3-4.9 5.1l-133.5 174.5q-1.8 2.5-4.2 4.5-2.3 2-5.1 3.4-2.7 1.5-5.7 2.3-3 0.7-6.1 0.9-3.1-0.2-6.1-1-3.1-0.8-5.8-2.2-2.8-1.4-5.2-3.4-2.4-2-4.3-4.5l-133.5-174.5c-2.8-3.7-6.5-6.6-10.7-8.6-4.1-2-8.7-3.1-13.3-3.1-4.6 0-9.2 1.1-13.4 3.1-4.1 2-7.8 4.9-10.6 8.6l-158 201.8v-312.7l158.3-199q2-2.7 4.5-4.8 2.6-2.1 5.6-3.6 3-1.5 6.2-2.2 3.3-0.8 6.6-0.8 3.4 0.1 6.8 0.9 3.3 0.8 6.3 2.3 3.1 1.5 5.7 3.7 2.7 2.1 4.8 4.8l132.8 173.3z"
                fill="#ffffff"
                id="Layer"
            />
            <path d="m1126.9 1098.7h-722.7v-229h722.7v229z" fill="#ffffff" id="Layer" />
        </svg>
    );
}
