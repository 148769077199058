import { IconProps } from './types';

export default function MultiBuildingIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M13.604 1.688h7.792a.917.917 0 0 1 .916.916v19.25a.459.459 0 0 1-.458.459h-8.708a.459.459 0 0 1-.459-.459V2.604a.917.917 0 0 1 .917-.917ZM15.438 4.438v.687M19.563 4.438v.687M15.438 7.875v.688M19.563 7.875v.688M15.438 11.313V12M19.563 11.313V12M15.438 14.75v.688M19.563 14.75v.688M15.438 18.188v.687M19.563 18.188v.687M1.688 12.688h8.25v9.166a.459.459 0 0 1-.459.459H2.146a.459.459 0 0 1-.458-.459v-9.166Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.604 9.938h6.417a.917.917 0 0 1 .916.916v1.834h-8.25v-1.834a.917.917 0 0 1 .917-.916ZM4.438 15.438v.687M7.188 15.438v.687M4.438 18.875v.688M7.188 18.875v.688"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
