import { IconProps } from './types';

export default function LaurentianIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.2"
            viewBox="0 0 1505 1504"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <g id="layer1">
                <g id="g6645">
                    <path d="m0 0h1504.3v1503.1h-1504.3z" fill="#0b3b6a" id="path6641" />
                    <path
                        d="m420.7 318.3l185.5 186v200.1h-199.8l-185.7-186.1zm-20.2 388.1l141.1 141.7-141.1 141.7h-263.1v-283.4zm351.1-48.7l141.9 141v465.8h-283.8v-465.8zm614.2 48.7v283.4h-263.1l-141.1-141.7 141.1-141.7zm-269.1-2h-199.8v-200.1l185.6-186 200 200zm-487.1-465.2h283.8v264l-141.9 141-141.9-141z"
                        fill="#f9ae26"
                        id="path6643"
                    />
                </g>
            </g>
        </svg>
    );
}
