import { IconProps } from './types';

export default function CIBCIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            version="1.2"
            viewBox="0 0 1560 1334"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <title>cibc-logo-colour-142x36-svg</title>
            <g id="Logo-/-Colour-/-desktop-header-142x36">
                <g id="Group">
                    <path
                        d="m1560 667l-453.9 615.8c-24.5 33.5-64.2 52.6-105.8 50.9h-439.6c42.2 1.7 82.4-18 106.8-52.4 44.4-59.1 453.1-614.3 453.1-614.3 0 0-408.7-555.4-453.1-614.3-24.4-34.5-64.5-54.3-106.8-52.6h439.6c41.6-1.7 81.3 17.5 105.8 51.1z"
                        fill="#c41f3e"
                        fillRule="evenodd"
                        id="Fill-3"
                    />
                    <path
                        d="m440.2 667l340.3 461.5-113 152.6c-24.4 34.4-64.6 54.1-106.8 52.4-41.6 1.8-81.4-17.3-105.9-50.9l-454-615.6 454-615.8c24.5-33.6 64.2-52.8 105.9-51.1 42.3-1.7 82.5 18.1 106.8 52.5 13.3 17.7 57.3 77.2 113 152.5z"
                        fill="#8b1d41"
                        fillRule="evenodd"
                        id="Fill-6"
                    />
                </g>
            </g>
        </svg>
    );
}
