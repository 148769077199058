import { IconProps } from './types';

export default function SquareFootageIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M23.44 0c-.3 0-.56.23-.56.56v.64H6.15V.56c0-.3-.26-.56-.56-.56s-.56.26-.56.56v2.36c0 .3.26.56.56.56s.56-.26.56-.56v-.64h16.73v.64c0 .3.26.56.56.56s.56-.26.56-.56V.56c0-.34-.26-.56-.56-.56ZM5.77 5.03h17.66c.3 0 .56.26.56.56v17.85c0 .3-.26.56-.56.56H5.77c-.3 0-.56-.26-.56-.56V5.59c0-.3.26-.56.56-.56Zm.56 17.85h16.54V6.15H6.34v16.72ZM3.49 5.62c0 .3-.26.56-.56.56h-.6v16.69h.6c.34 0 .56.26.56.56s-.26.56-.56.56H.56c-.3 0-.56-.26-.56-.56s.26-.56.56-.56h.64V6.19H.56c-.3 0-.56-.22-.56-.56s.26-.56.56-.56h2.36c.3 0 .56.26.56.56Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
