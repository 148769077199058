import { IconProps } from './types';

export default function HouseV2Icon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M3.063 9.616v11.321h4.124v-5.5a1.375 1.375 0 0 1 1.375-1.375h1.376a1.375 1.375 0 0 1 1.374 1.375v5.5h9.626V9.617"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m22.313 10.625-9.5-6.966a1.375 1.375 0 0 0-1.626 0l-9.5 6.966"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.438 5.583v-2.52h4.124v5.545M14.063 14.063h4.124v4.124h-4.125v-4.125ZM1.688 20.938h20.625"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
