import { IconProps } from './types';

export default function TownhouseIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M10.5 22.313v-2.75a1.375 1.375 0 0 1 2.75 0v2.75M16.688 7.188v15.125M7.063 22.313V7.188M7.37 3.822 5.687 7.187h12.375L16.38 3.822a1.374 1.374 0 0 0-1.23-.76H8.6a1.375 1.375 0 0 0-1.23.76ZM13.25 3.063V1.687M1.688 22.313h20.625M9.813 15.438h4.124M9.813 12.688h4.124M9.813 9.938h4.124"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
